import React from 'react';
import NavigationMenu from './NavigationMenu';

const HalfIronMan = () => {
  return (
    <div>
      <NavigationMenu />
      <div style={{ padding: '20px' }}>
        <h1>Half Iron Man</h1>
        <p>This page will contain information related to the Half Iron Man event.</p>
      </div>
    </div>
  );
};

export default HalfIronMan;