import React from 'react';
import SummaryTable from './SummaryTable';
import DetailGraph from './DetailGraph';
import YogaCount from './YogaCount';

const MetricContainer = ( {yogaCount, allYogaStats, allYogaActivities} ) => {
  return (
      <> 
        <YogaCount count={yogaCount} />
        <SummaryTable data={allYogaStats} />
        <DetailGraph data={allYogaActivities}/>
      </>
  );
};

export default MetricContainer;