import React from 'react';
import '../styling/SummaryTable.css';

const SummaryTable = ({ data }) => {
  const summaryData = [
    { metric: 'Duration', average: data.avg_duration, trend: `${data.duration_change}%` },
    { metric: 'Avg HR', average: data.avg_avg_hr, trend: `${data.avg_hr_change}%` },
    { metric: 'Max HR', average: data.avg_max_hr, trend: `${data.max_hr_change}%` },
    { metric: 'Avg RR', average: data.avg_avg_rr, trend: `${data.avg_rr_change}%` },
    { metric: 'Max RR', average: data.avg_max_rr, trend: `${data.max_rr_change}%` },
    { metric: 'Calories', average: data.avg_calories, trend: `${data.calories_change}%` },
  ];

  const getTrendStyle = (metric, trend) => {
    const trendValue = parseFloat(trend);
    if (metric.includes('HR')) {
      return {
        color: trendValue < 0 ? 'green' : 'red'
      };
    }
    return {
      color: trendValue < 0 ? 'red' : 'green'
    };
  };

  return (
    <div className="summary-table-container">
      <table className="table">
        <thead>
          <tr>
            <th>Metric</th>
            <th>Average</th>
            <th>Trend</th>
          </tr>
        </thead>
        <tbody>
          {summaryData.map((item, index) => (
            <tr key={index}>
              <td>{item.metric}</td>
              <td>{item.average}</td>
              <td style={getTrendStyle(item.metric, item.trend)}>{item.trend}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SummaryTable;