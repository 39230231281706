import React, { useState, useEffect } from 'react';
import NavigationMenu from './NavigationMenu';
import MetricContainer from './MetricContainer';
import { fetchYogaStreak, fetchAllYogaData, fetchYogaStats } from '../database/database';
import '../styling/App.css';

const HomePage = () => {
  const [yogaCount, setYogaCount] = useState(-1);
  const [allYogaActivities, setAllYogaActivities] = useState('0');
  const [allYogaStats, setAllYogaStats] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const streak = await fetchYogaStreak();
      const activities = await fetchAllYogaData();
      const stats = await fetchYogaStats();
      setYogaCount(streak);
      setAllYogaActivities(activities);
      setAllYogaStats(stats);

      const expirationTime = new Date().getTime() + 3 * 60 * 60 * 1000; // 3 hours in milliseconds
      localStorage.setItem('yogaStreak', JSON.stringify(streak));
      localStorage.setItem('allYogaActivities', JSON.stringify(activities));
      localStorage.setItem('allYogaStats', JSON.stringify(stats));
      localStorage.setItem('expirationTime', expirationTime);
    };

    const checkExpiration = () => {
      const expirationTime = localStorage.getItem('expirationTime');
      if (expirationTime && new Date().getTime() > expirationTime) {
        localStorage.removeItem('yogaStreak');
        localStorage.removeItem('allYogaActivities');
        localStorage.removeItem('allYogaStats');
        localStorage.removeItem('expirationTime');
        return false;
      }
      return true;
    };

    const loadData = () => {
      const streak = JSON.parse(localStorage.getItem('yogaStreak'));
      const activities = JSON.parse(localStorage.getItem('allYogaActivities'));
      const stats = JSON.parse(localStorage.getItem('allYogaStats'));

      if (streak && activities && stats) {
        setYogaCount(streak);
        setAllYogaActivities(activities);
        setAllYogaStats(stats);
        setLoading(false);
      } else {
        fetchData().finally(() => setLoading(false));
      }
    };

    if (checkExpiration()) {
      loadData();
    } else {
      fetchData().finally(() => setLoading(false));
    }
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <NavigationMenu />
        <div className="title-container">
          <img src="/yoga-animation.gif" alt="Yoga Animation" className="yoga-animation" />
          <h1 className="App-title">HabitSync</h1>
          <img src="/yoga-animation.gif" alt="Yoga Animation" className="yoga-animation" />
        </div>
        {loading ? (
          <div className="loading-spinner">
            <img src="loading.gif" alt="Loading..." />
          </div>
        ) : (
          <MetricContainer yogaCount={yogaCount} allYogaStats={allYogaStats} allYogaActivities={allYogaActivities} />
        )}
      </header>
    </div>
  );
};

export default HomePage;