import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import '../styling/DetailGraph.css';

// Register the necessary components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const DetailGraph = ({ data }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const formatDuration = (duration) => {
    const totalSeconds = Math.round(duration);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  const formatStartTime = (startTime) => {
    const date = new Date(startTime);
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' }
    return date.toLocaleString('en-US', options);
  };

  const filteredData = isMobile ? [...data].slice(0,5).reverse() : [...data].reverse();

  const chartData = {
    labels: filteredData.map(item => formatStartTime(item.start_time)),
    datasets: [
      {
        label: 'Avg HR',
        data: filteredData.map(item => item.avg_hr),
        borderColor: 'rgba(75,192,192,1)',
        fill: false,
      },
      {
        label: 'Max HR',
        data: filteredData.map(item => item.max_hr),
        borderColor: 'rgba(255,99,132,1)',
        fill: false,
      },
      {
        label: 'Avg RR',
        data: filteredData.map(item => item.avg_rr),
        borderColor: 'rgba(54,162,235,1)',
        fill: false,
      },
      {
        label: 'Max RR',
        data: filteredData.map(item => item.max_rr),
        borderColor: 'rgba(153,102,255,1)',
        fill: false,
        yAxisID: 'y',
      },
      {
        label: 'Calories',
        data: filteredData.map(item => item.calories),
        borderColor: 'rgba(255,159,64,1)',
        fill: false,
        yAxisID: 'y',
      },
      {
        label: 'Duration',
        data: filteredData.map(item => item.duration),
        borderColor: 'rgba(0,128,0,1)',
        fill: false,
        yAxisID: 'y1',
      },
    ],
  };

  const options = {
    scales: {
      y: {
        type: 'linear',
        display: true,
        position: 'left',
      },
      y1: {
        type: 'linear',
        display: true,
        position: 'right',
        grid: {
          drawOnChartArea: false,
        },
        ticks: {
          callback: function(value) {
            return formatDuration(value);
          }
        }
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function(context) {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            if (context.dataset.label === 'Duration') {
              label += formatDuration(context.raw);
            } else {
              label += context.raw;
            }
            return label;
          }
        }
      }
    }
  };

  return (
    <div>
      <div className="chart-container">
        <Line data={chartData} options={options} />
      </div>
    </div>
  );
};

export default DetailGraph;