export async function fetchAllYogaData() {
  try {
    // API call to fetch all Yoga Data
    const response = await fetch('https://habitsync-backend.lemonbeach-3574a355.canadacentral.azurecontainerapps.io/api/yoga-activities');
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}

export async function fetchYogaStreak() {
    try {
      // API call to fetch Yoga Streak
      const response = await fetch('https://habitsync-backend.lemonbeach-3574a355.canadacentral.azurecontainerapps.io/api/yoga-streak');
      const data = await response.json();
      return data.streak;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  }
  
  export async function fetchYogaStats() {
    try {
      // API call to fetch Yoga Stats
      const response = await fetch('https://habitsync-backend.lemonbeach-3574a355.canadacentral.azurecontainerapps.io/api/yoga-stats');
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  }
