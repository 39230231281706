import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../styling/NavigationMenu.css';

const NavigationMenu = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(true);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setIsMenuOpen(true);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <button className="menu-toggle" onClick={toggleMenu}>
        ☰
      </button>
      <nav className={`navigation-menu ${isMenuOpen ? '' : 'hidden'}`}>
        <ul>
          <li>
            <Link to="/" onClick={toggleMenu}>Home</Link>
          </li>
          <li>
            <Link to="/half-iron-man" onClick={toggleMenu}>Half Iron Man</Link>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default NavigationMenu;